@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";
// Vex Core
@import "./@vex/styles/core";

/*
  You can override any CSS Variable or style here
  Example:

  :root {
   --sidenav-background: #333333;
  }

  All possible variables can be found in @vex/styles/core or by simply inspecting the element you want to change
  in Chrome/Firefox DevTools
*/

/**
  Uncomment the below code and adjust the values to fit your colors, the application will automatically adjust
 */
:root {
  // --color-primary: blue;
  // --color-primary-contrast: white;

  // --color-accent: yellow;
  // --color-accent-contrast: black;

  // --color-warn: yellow;
  // --color-warn-contrast: black;
}


.measurements {
  table {
    border-collapse: collapse;
    width: 100%;
  }

  table, th, td {
    border: 1px solid #ccc;
  }

  th, td {
    padding: 4px;
    text-align: left;
  }

  th {
    background-color: #f2f2f2;
  }
}

.hiddenCanvas {
  width:3000px !important;
  height:1380px !important;
}
